import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../../components/Layout'
import { useLocation } from '@reach/router'

const ThankYou = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.href = '/'
        }, 1000 * 10)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    return (
        <Layout>
            <Helmet>
                <meta name='robots' content='noindex' />
                <meta name='robots' content='nofollow' />
            </Helmet>
            <section
                className='hero-margin pb-4'
                style={{
                    minHeight: '50vh',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <div className='container-1'>
                    <div className='row mt-3'>
                        <div className='col-md-8 offset-2 text-center pt-0'>
                            <h1 className='mt-3' style={{ fontSize: '72px', color: '#FF9448' }}>
                                Thank you!
                            </h1>
                            <br />
                            <p className='hero-description-dark' style={{ marginTop: '20px' }}>
                                We are excited to receive your registration! See you{' '}
                                <span style={{ textTransform: 'capitalize' }}>
                                    {queryParams.get('meetup')
                                        ? `in ${queryParams.get('meetup')}`
                                        : 'there'}
                                </span>
                                !
                            </p>
                        </div>
                    </div>
                </div>{' '}
            </section>
        </Layout>
    )
}
export default ThankYou
